import {
  Box,
  Container,
  Grid,
  NativeSelect,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import {
  Area,
  AreaChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "./App.scss";
import { InputSlider } from "./InputSlider";

const substances = [
  {
    key: "LSD",
    scaleValues: [15, 25, 75, 150, 300, 1000],
    toleranceCalc: true,
    unit: "μg",
  },
  {
    key: "Shrooms",
    scaleValues: [0.25, 0.5, 1, 2.5, 5, 15],
    toleranceCalc: true,
    unit: "g",
  },
  {
    key: "1cP-LSD",
    scaleValues: [15, 25, 75, 150, 300, 1000],
    toleranceCalc: true,
    unit: "μg",
  },
  {
    key: "1B-LSD",
    scaleValues: [15, 25, 75, 150, 300, 1000],
    toleranceCalc: true,
    unit: "μg",
  },
  {
    key: "1P-ETH-LAD",
    scaleValues: [25, 30, 60, 100, 200, 1000],
    toleranceCalc: true,
    unit: "μg",
  },
  {
    key: "1P-LSD",
    scaleValues: [15, 25, 75, 150, 300, 1000],
    toleranceCalc: true,
    unit: "μg",
  },
  {
    key: "ETH-LAD",
    scaleValues: [15, 30, 60, 150, 225, 1000],
    toleranceCalc: true,
    unit: "μg",
  },
  {
    key: "4-AcO-DET",
    scaleValues: [5, 10, 15, 20, 35, 100],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "4-AcO-DiPT",
    scaleValues: [3, 5, 15, 30, 45, 100],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "4-AcO-DMT",
    scaleValues: [5, 7.5, 15, 25, 45, 100],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "4-AcO-MET",
    scaleValues: [5, 10, 20, 30, 50, 100],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "4-AcO-MiPT",
    scaleValues: [5, 10, 15, 20, 35, 100],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "4-HO-DET",
    scaleValues: [5, 10, 15, 20, 35, 100],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "4-HO-DiPT",
    scaleValues: [3, 5, 10, 20, 30, 100],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "4-HO-DMT",
    scaleValues: [5, 10, 15, 25, 40, 100],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "4-HO-DPT",
    scaleValues: [20, 40, 60, 90, 130, 250],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "4-HO-MET",
    scaleValues: [5, 10, 15, 25, 45, 100],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "4-HO-MiPT",
    scaleValues: [5, 10, 15, 20, 35, 100],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "4-HO-MPT",
    scaleValues: [5, 10, 20, 30, 50, 100],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "4-HO-MPT",
    scaleValues: [5, 10, 20, 30, 50, 100],
    toleranceCalc: true,
    unit: "mg",
  },
  {
    key: "2C-B",
    scaleValues: [5, 10, 15, 25, 45, 100],
    toleranceCalc: false,
    unit: "mg",
  },
  {
    key: "2C-C",
    scaleValues: [5, 15, 30, 50, 90, 100],
    toleranceCalc: false,
    unit: "mg",
  },
  {
    key: "2C-D",
    scaleValues: [3, 10, 25, 50, 100, 250],
    toleranceCalc: false,
    unit: "mg",
  },
  {
    key: "2C-E",
    scaleValues: [2, 5, 10, 15, 30, 100],
    toleranceCalc: false,
    unit: "mg",
  },
];

const days = 12;

export const App: React.FC = () => {
  const theme = useTheme();
  const [dose, setDose] = useState(333.33);
  const [desired, setDesired] = useState(333.33);
  const [substanceKey, setSubstanceKey] = useState("LSD");
  const substance = useMemo(
    () => substances.find((substance) => substance.key === substanceKey)!,
    [substanceKey]
  );
  const scale = useCallback(
    (n: number) => {
      let i = Math.floor(n / 100);

      const scaleValues = substance.scaleValues;
      const min = i === 0 ? 0 : scaleValues[i - 1];
      const max = scaleValues[i];
      const out = ((n % 100) / 100) * (max - min) + min;
      const final = isNaN(out) ? scaleValues[i - 1] : out;
      return Math.round(final * 100) / 100;
    },
    [substance]
  );
  const estimated = useMemo(
    () =>
      new Array(days).fill(0).map((_, i) => {
        const sDose = scale(dose);
        const sDesired = scale(desired);
        if (sDesired < 0.01) {
          return 0;
        }

        const estimated =
          (sDose / 100) * 280.059565 * Math.pow(i + 1, -0.412565956) +
          (sDesired - sDose);
        // const estimated =
        //   sDose * (Math.log(i + 1) / Math.log(0.25) + 1.8) + sDesired;
        if (estimated < sDesired) {
          return sDesired;
        }

        return estimated;
      }),
    [dose, desired, scale]
  );

  return (
    <Container>
      <Paper
        elevation={3}
        style={{ padding: 10, marginBottom: 15, marginTop: 15 }}
      >
        <h1>lsd.cafe - Psychedelic Dosage Calculator</h1>
      </Paper>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} style={{ padding: 10 }}>
              <Typography variant="overline" display="block" gutterBottom>
                Substance
              </Typography>
              <NativeSelect
                value={substanceKey}
                onChange={(e) => setSubstanceKey(e.target.value)}
                style={{ width: "100%" }}
              >
                {substances
                  .filter((substance) => substance.toleranceCalc)
                  .map((substance) => (
                    <option value={substance.key}>{substance.key}</option>
                  ))}
              </NativeSelect>
              <div className="divider" />
              <InputSlider
                label="Last dose"
                value={dose}
                onChange={(val) => setDose(val as number)}
                scale={scale}
                unit={substance.unit}
              />
              <div className="divider" />
              <InputSlider
                label="Desired dose"
                value={desired}
                onChange={(val) => setDesired(val as number)}
                scale={scale}
                unit={substance.unit}
              />
            </Paper>
            <Paper elevation={3} style={{ padding: 10, marginTop: 15 }}>
              <strong>Disclaimer:</strong> All of the information presented on
              this page is unverified. lsd.cafe does not encourage the human
              consumption of any of those chemicals.{" "}
              <strong>
                ALL CHEMICALS LISTED ARE NOT FOR HUMAN CONSUMPTION.
              </strong>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper elevation={3} style={{ padding: 15 }}>
              <ResponsiveContainer width={"99%"} height={450}>
                <AreaChart
                  width={500}
                  height={400}
                  data={estimated.map((val, i) => ({
                    estimated: Math.round(val * 100) / 100,
                    name: `${i + 1}`,
                  }))}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 20,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    interval={0}
                    stroke="white"
                    tickFormatter={(val) => `${val}`}
                  >
                    <Label value="Days after dose" fill="white" dy={20} />
                  </XAxis>
                  <YAxis
                    stroke="white"
                    tickFormatter={(val) => `${val}${substance.unit}`}
                  />
                  <Tooltip
                    content={({ active, payload, label }) => {
                      if (active && payload && payload.length) {
                        return (
                          <div className="tooltip">
                            On <strong>day {label}</strong> after taking{" "}
                            <strong>
                              {scale(dose)}
                              {substance.unit}
                            </strong>{" "}
                            of {substanceKey}, you would need need to take{" "}
                            <strong>
                              {payload[0].value}
                              {substance.unit}
                            </strong>{" "}
                            to experience the effects of a{" "}
                            <strong>
                              {scale(desired)}
                              {substance.unit}
                            </strong>{" "}
                            dose.
                          </div>
                        );
                      }

                      return null;
                    }}
                  />
                  <Area
                    type="monotone"
                    dataKey="estimated"
                    stroke={theme.palette.primary.dark}
                    fill={theme.palette.primary.dark}
                  />
                  <ReferenceLine
                    y={scale(desired)}
                    stroke={theme.palette.secondary.main}
                    strokeDasharray="3 3"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
