import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";

export interface InputSliderProps {
  value: number;
  onChange: (value: number) => void;
  label: string;
  unit: string;
  scale: (value: number) => number;
}

const marks = [
  {
    value: 100,
    label: "Threshold",
  },
  {
    value: 200,
    label: "Light",
  },
  {
    value: 300,
    label: "Common",
  },
  {
    value: 400,
    label: "Strong",
  },
  {
    value: 500,
    label: "Heavy",
  },
];

export const InputSlider: React.FC<InputSliderProps> = ({
  value,
  onChange,
  label,
  unit,
  scale,
}) => {
  const handleSliderChange = (_: any, newValue: number | number[]) => {
    onChange(Math.round(newValue as number));
  };

  return (
    <>
      <Typography variant="overline" display="block">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            {label}
          </Grid>
          <Grid item xs textAlign="right" style={{ textTransform: "none" }}>
            {scale(value)}
            {unit}
          </Grid>
        </Grid>
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Slider
              value={value}
              onChange={handleSliderChange}
              step={1}
              min={0}
              max={600}
              marks={marks}
              scale={scale}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
